@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Nunito+Sans:wght@400;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  width: 100%;
}
.footer .card-message {
  width: 100%;
}
.footer .chk-security-message {
  clear: both;
  display: flex;
  font-size: 0.85rem;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.footer .chk-multiple-cards {
  display: flex;
  justify-content: center;
}
.footer .chk-add-credit-card {
  color: #fff;
  padding: 6px 15px;
  border-radius: 5px;
  text-transform: unset;
  background-color: #4caf50;
}
.footer .chk-submit {
  margin-top: 10px;
}
.footer .chk-security-badges {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.footer .tooltip-email-information {
  display: flex;
  position: relative;
  flex-direction: column;
}
.footer .autoSuggestContainer {
  top: 83px;
  z-index: 15;
  position: absolute;
}
.footer .cepNotFound {
  color: #f59211;
  display: flex;
  font-size: 0.775rem;
}
.footer .absolute-label {
  top: 76px;
  position: absolute;
}
.footer .cpf-form {
  margin: 12px 0 0 0;
}
.footer .cepNotFound svg {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}
.footer .autoSuggestContainer .autoSuggest {
  width: 270px;
  cursor: pointer;
}
.footer .autoSuggestContainer .autoSuggest .item {
  height: 30px;
  padding: 0 5px;
}
.footer .autoSuggestContainer .autoSuggest .item:hover {
  background-color: #f1f1f1;
}
.footer .autoSuggestContainer .autoSuggest .item.actualEmail {
  background-color: #f1f1f1;
}
.footer .tooltip-email-information .modifyEmailLabel {
  top: -9px;
}
.footer .tooltip-email-information .information {
  color: #868686;
  margin: 13px 0 0 0;
  position: absolute;
  font-size: 12px;
  font-style: italic;
}
.footer .chk-security-badges svg {
  fill: rgb(49, 136, 52);
  width: 35px;
  height: 40px;
  fill-rule: evenodd;
}
.footer .chk-security-badges div {
  display: flex;
  align-items: center;
}
.footer .chk-security-badges div:not(:last-child) {
  margin-right: 10px;
}
.footer .chk-security-badges div .text {
  color: #1ab25a;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.666rem;
  align-items: flex-start;
  line-height: normal;
  margin-left: 5px;
  text-transform: uppercase;
  justify-content: flex-start;
}
.footer .chk-security-badges div .text .buy {
  font-weight: 700;
  line-height: normal;
}
.footer .chk-security-badges div .text span a {
  color: #1ab25a;
  text-decoration: none;
}
.footer .chk-security-badges div .text span a:hover {
  text-decoration: underline;
}
.footer .chk-submit .paymentButton {
  height: 60px;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: unset;
}
.footer .chk-add-credit-card svg {
  width: 30px;
  margin: 0 10px 0 0;
}
.footer .chk-add-credit-card:hover {
  background-color: #388e3c;
}
.footer .chk-add-credit-card.add svg {
  width: 14px;
  margin: 0 10px 0 0;
}
@media screen and (min-width: 960px) {
  .footer .chk-multiple-cards {
    margin: 14px 0 0;
  }
}
.footer .chk-security-message svg {
  fill: #21883b;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.footer_mobile .security {
  color: #009358;
  display: flex;
  font-size: 0.8rem;
  margin-top: 16px;
  align-items: center;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
}

.footer_mobile .security {
  color: #009358;
  display: flex;
  font-size: 0.8rem;
  margin-top: 16px;
  align-items: center;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
}

.footer_mobile .security svg {
  fill: #009358;
  width: 40px;
}

.footer_mobile .security p {
  margin: 0;
}

/* loader */
.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  position: fixed;
  background: rgba(243, 241, 241, 0.7);
  justify-content: center;
  align-items: center;
}
.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(138, 43, 226, 0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(138, 43, 226, 0.8);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(138, 43, 226, 1);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(138, 43, 226, 0.8);
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(138, 43, 226, 0.4);
}

@keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}

button:disabled,
button:disabled:hover {
  background-color: #868686;
  border-color: #868686;
  cursor: not-allowed;
}

body {
  background-color: #f5f7ff;
}

